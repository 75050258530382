import React from 'react';
import sectionImg from "../images/top_img.png"



function Topsection(){
    return (
        <div id="top_section" className=" banner_main">
        <div className="container  max-w-full mx-auto sm:px-4">
            <div className="flex flex-wrap">
                <div className="md:w-full md:pr-4 md:pl-4">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                       
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="container  max-w-full mx-auto sm:px-4">
                                    <div className="carousel-caption p-0 bottom-0">
                                        <div className="flex flex-wrap">
                                            <div className="md:w-1/2  md:pr-4 md:pl-4">
                                                <div className="con_img">
                                                    <figure>
                                                        <img  src={sectionImg} alt="" />
                                                        </figure>
                                                </div>
                                            </div>
                                            <div className="md:w-1/2 md:pr-4 md:pl-4 md:pt-40 -pt-4">
                                                <div className="text-left max-w-[540px]">
                                                    <h1 className='text-white md:text-[55px] text-2xl md:leading-[65px] font-bold md:pb-[45px] pb-4'>Progress <br/> Your Bitcoin Money Success</h1>
                                                    <p className='text-white md:leading-7 md:font-medium md:pb-16 pb-10 md:text-lg'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                                                    </p> 
                                                    <a className="mr-4 border-2 md:px-14 md:py-4 px-2 py-2 text-white hover:text-yellow-600 hover:border-yellow-600" href="Javascript:void(0)">Read More </a>
                                                    <a className="mr-4 border-2 md:px-14 md:py-4 px-2 py-2 text-white hover:text-yellow-600 hover:border-yellow-600" href="Javascript:void(0)">Get A Quote </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           </div>
                           <div className='flex justify-center items-center gap-6 pt-10 md:pt-1'>
                        <a className=" w-[72px] h-[72px] opacity-100 text-[33px] text-[#9197a6] rounded-full border-[#9197a6] border-solid border-[5px] top-[107%] left-[44.3%]" href="#myCarousel" role="button" data-slide="prev">
                            <div className='text-center'>
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                            </div>
                        </a>
                        <a className=" w-[72px] h-[72px] opacity-100 text-[33px] text-[#9197a6] rounded-full border-[#9197a6] border-solid border-[5px] top-[107%] right-[44.3%]" href="#myCarousel" role="button" data-slide="next">
                            <div className='text-center'>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Topsection;