import React from 'react';
import workImg from "../images/work.png"

function Work() {
    return (
        <div class="pt-20 pb-[90px] px-0">
        <div class="container mx-auto sm:px-4">
            <div class="flex flex-wrap ">
                <div class="md:w-full pr-4 pl-4">
                    <div class="pb-16 text-center">
                        <h2 className='text-5xl font-semibold text-white'>How It Works</h2>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div class="md:w-full w-full pr-4 md:pl-40">
                    <div >
                        <figure className='m-0' >
                            <img className='md:max-w-full w-screen h-auto flex justify-center items-center' src={workImg} alt="" />
                            </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Work;