import React from 'react';
import sectionImg from "../Images/icon-1.png"
import sectionImg1 from "../Images/icon-2.png"
import sectionImg2 from "../Images/icon-3.png"
import sectionImg3 from "../Images/icon-4.png"

function Service()  {
    return (
        <div className="w-full float-left pt-24">
      <div className="container mx-auto sm:px-4">
      <div className='flex justify-center items-center'>

    
<div className='w-16 h-4 bg-red-500 rounded bottom-[-80px]  '>

</div>    </div>
        <h1 className="w-full float-left text-4xl text-black pt-2 uppercase font-bold text-center pb-6">WHAT WE DO</h1>
        <p className=" text-base text-black text-center">It is a long established fact that a reader will be distracted by the readable content of a </p>
        <div className="w-full float-left pt-24 pb-0">
          <div className="flex flex-wrap">
          <div className="sm:w-1/4 pr-4 pl-4 pb-10">
            <div className="w-full float-left bg-blue-500 rounded-2xl px-[0px] py-[55px] hover:bg-red-500">
              <div className="w-full float-left text-center min-h-[70px] ">
                <div className='flex justify-center items-center '>
               <img src={sectionImg} alt="" />
               </div>
                </div>
              <h3 className="w-full float-left text-xl text-white text-center uppercase">Accounting</h3>
              <p className="w-full float-left text-base text-white text-center ml-0">Lorem Ipsum is simply dummy text of the printing and</p>
              <div className="  md:pl-[25%] pl-[35%] mt-10">
          
                <a  className=' float-left text-base text-white text-center border-2  rounded-lg border-slate-200 hover:text-white hover:border-2  hover:border-slate-200 px-8' href="#">Read More </a>
                </div>
              
            </div>
          </div>
          <div className="sm:w-1/4 pr-4 pl-4 pb-10">
            <div className="w-full float-left bg-red-500 rounded-2xl px-[0px] py-[55px] ">
              <div className="w-full float-left text-center min-h-[70px]">
              <div className='flex justify-center items-center'>
               <img src={sectionImg1} alt="" />
               </div>

                </div>
              <h3 className="w-full float-left text-xl text-white text-center uppercase">Advisor</h3>
              <p className="w-full float-left text-base text-white text-center ml-0">Lorem Ipsum is simply dummy text of the printing and</p>
              <div className="  md:pl-[25%] pl-[35%] mt-10"><a  className=' float-left text-base text-white text-center border-2  rounded-lg border-slate-200 hover:text-white hover:border-2  hover:border-slate-200 px-8' href="#">Read More </a></div>
            </div>
          </div>
          <div className="sm:w-1/4 pr-4 pl-4 pb-10">
            <div className="w-full float-left bg-blue-500 rounded-2xl px-[0px] py-[55px] hover:bg-red-500">
              <div className="w-full float-left text-center min-h-[70px]">
              <div className='flex justify-center items-center'>
               <img src={sectionImg2} alt="" />
               </div>

                </div>
              <h3 className="w-full float-left text-xl text-white text-center uppercase">Investment</h3>
              <p className="w-full float-left text-base text-white text-center ml-0">Lorem Ipsum is simply dummy text of the printing and</p>
              <div className="md:pl-[25%] pl-[35%] mt-10"><a  className=' float-left text-base text-white text-center border-2  rounded-lg border-slate-200 hover:text-white hover:border-2  hover:border-slate-200 px-8' href="#">Read More </a></div>
            </div>
          </div> 
          <div className="sm:w-1/4 pr-4 pl-4 ">
            <div className="w-full float-left bg-blue-500 rounded-2xl px-[0px] py-[55px] hover:bg-red-500">
              <div className="w-full float-left text-center min-h-[70px]">
              <div className='flex justify-center items-center'>
               <img src={sectionImg3} alt="" />
               </div>

                </div>
              <h3 className="w-full float-left text-xl text-white text-center uppercase">Financial</h3>
              <p className="w-full float-left text-base text-white text-center ml-0">Lorem Ipsum is simply dummy text of the printing and</p>
              <div className=" md:pl-[25%] pl-[35%] mt-10"><a  className=' float-left text-base text-white text-center border-2  rounded-lg border-slate-200 hover:text-white hover:border-2  hover:border-slate-200 px-8' href="#">Read More </a></div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    );
};

export default Service;