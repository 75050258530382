import React from 'react'
import Footer from './Components/Footer';
import Client from './Components/Client';
import Team from './Components/Team';
import Project from './Components/Project';
import Service from './Components/Service';
import About from './Components/About';
import Header from './Components/Header';
import  Helmet from 'react-helmet'

const Bankosys = () => {
  return (
    <div>

<Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Banking  || Bankose </title>
      </Helmet>


   

    <div className='overflow-hidden'>
        <Header/>
        <About/>
        <Service/>
        <Project/>
        <Team/>
        <Client/>
        <Footer/>
    </div> </div>
  )
}
export default Bankosys;