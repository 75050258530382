import React from 'react';
import testImg from "../images/clint.jpg"
import iconImg from "../images/icon.png"

function Testinomial()  {
    return (
        <div class="mt-[90px] pt-20 ">
        <div class="container mx-auto sm:px-4 max-w-full ">
            <div class="flex flex-wrap ">
                <div class="md:w-full md:pr-4 pl-4">
                    <div class="mx-10 my-0 pt-[90px] pb-[142px] px-0 border-[#fff] border-solid border-[1px]">
                        <div class="flex flex-wrap">
                            <div class="md:w-full md:pr-4 pl-4">
                                <div class="pb-[60px] text-center">
                                    <h2 className='text-5xl font-semibold text-white'>Testimonial</h2>
                                </div>
                            </div>
                        </div>
                        <div class=" flex items-center flex-wrap md:pl-48">
                            <div class="md:w-4/5 md:pr-4 pl-4 md:mx-1/6">
                                <div id="testimo" class="carousel slide our_testimonial" data-ride="carousel">
                                  
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="container  mx-auto sm:px-4">
                                                <div class="carousel-caption p-0">
                                                    <div class="flex flex-wrap">
                                                        <div class="md:w-1/4 md:pr-4 pl-4">
                                                            <div class="testomoniam_text text-center">
                                                                <i><img className='rounded-[10%]' src={testImg} alt="" /></i>
                                                                <h3 className='mt-6 text-base text-white p-0 font-bold leading-5'>MorGan Den</h3>
                                                                <span className='block text-yellow-500 text-base leading-5  pt-0.5 pb-[30px] px-0'>Bitcoin</span>
                                                                <img src={iconImg} alt="" />
                                                            </div>
                                                        </div>
                                                        <div class="md:w-3/4 md:pr-4 pl-4">
                                                            <div class="testomoniam_text text-center">
                                                                <p className='pt-5 block text-white text-lg'>more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and webmore-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and webmore-or-less normal distribution of</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:ml-96 md:pl-96 md:pt-1 pt-20	'>
                                        <ol class="flex justify-center items-center flex-wrap right-0 bottom-[-52px] left-[inherit]  ">
                                        <li className='w-6 h-6 inline-block  cursor-pointer rounded-[20px] bg-blue-100 mr-2' data-target="#testimo" data-slide-to="0" ></li>
                                        <li className='w-6 h-6 inline-block bg-yellow-500 cursor-pointer rounded-[20px] mr-2' data-target="#testimo" data-slide-to="1"></li>
                                        <li className='w-6 h-6 inline-block bg-yellow-500 cursor-pointer rounded-[20px]' data-target="#testimo" data-slide-to="2"></li>
                                    </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    );
};

export default Testinomial;