
import { BrowserRouter , Routes,Route} from "react-router-dom"
import Banking from "./Banking";
import Bankosys from "./Bankosys/Bankosys";
import Cbti from "./Cbti/Cbti";
import Finance from "./Finance/Finance";

function App() {
  return (
    <div >
     <BrowserRouter>
      <Routes>
<Route path ="/" element={<Banking/>}/>
<Route path ="/financial-services" element={<Bankosys/>}/>
<Route path ="/cryptocurrency" element={<Finance/>}/>
<Route path ="/personal-loan-online" element={<Cbti/>}/>

      
</Routes>
</BrowserRouter>
    </div>
  );
}

export default App;
