import React from 'react'
import Footer from './Components/Footer';
import Call from './Components/Call';
import Blog from './Components/Blog';
import Benifit from './Components/Benifit';
import Fact from './Components/Fact';
import Test from './Components/Test';
import Loan from './Components/Loan';
import Contant from './Components/Contant';
import Video from './Components/Video';
import Services from './Components/Services';
import About from './Components/About';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
import { Helmet } from 'react-helmet';

 const Cbti = () => {
  return (
    <>
    
    <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Banking  || CBTI </title>
      </Helmet>
   
    <div className='overflow-hidden' >
      <Header/>
      <Navbar/>
      <About/>
      <Services/>
      <Video/>
      <Contant/>
      <Loan/>
      <Test/>
      <Fact/>
      <Benifit/>
      <Blog/>
      <Call/>
        <Footer/>
    </div>
    </>
  )
}
export default Cbti;