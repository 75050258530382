import React from 'react';
import aboutImg from '../Images/image 119.png'
import aboutImg1 from '../Images/about-4-1.png.png'

const About = () => {
    return (
        <section className="relative pt-28 pb-28">
        <div className="absolute w-24 h-32 bg-no-repeat top-[97px] left-[146px]"></div>
        <div className="container mx-auto sm:px-4 ">  
            <div className="flex flex-wrap">
                <div className="lg:w-1/2 pr-4 pl-4">
                    <div className="about-four__content">
                        <div className="mb-12 text-left">
                            <p className="uppercase text-sm font-bold m-0 leading-none relative top-[-1px] text-[#e63a27] tracking-widest	">About Company</p>
                            <h2 className="m-0 font-bold text-5xl relative -mt-1 text-black bottom-[-10px]">Personal loans to fulfill your dreams</h2>
                        </div>
                        <div className="flex items-center mb-6 max-w-[501px]">
                            <div className="relative mr-4 pt-2 pl-5 before:w-16 before:h-16 before:absolute before:top-0 before:left-0 rounded-[50%] bg-[#f2ede9]">
                                <i className='text-6xl relative ' >
                                    <img  className="icon-loan" src={aboutImg}alt=""/>
                                </i>
                            </div>
                            <div className="about-four__box__content">
                                <h3 className="m-0 text-xl font-semibold mt-2 leading-6	text-black">We’re in this business since 1987 and we provide the best
                                    services.</h3>
                            </div>
                        </div>
                        <p className="m-0 mb-10 max-w-[501px]">Alteration in some form by injected humour. Duis aute irure dolor lipsum
                            is simply free text available in the local markets in reprehenderit.</p>
                        
                        <div className="flex flex-wrap">
                            <div className="lg:w-1/2 pr-4 pl-4">
                                <div className="about-four__feature">
                                    <div className="flex items-center">
                                        <div className="flex items-center">
                                           
                                        </div>
                                        <h3 className="m-0 text-lg font-bold text-black leading-6">Direct card payment</h3>
                                       
                                    </div>
                                    <div className="m-0 mt-2">Lorem ipsum dolor sit ame ed consectetur nod.
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-1/2 pr-4 pl-4">
                                <div className="about-four__feature">
                                    <div className="flex items-center">
                                        <div className="flex items-center">
                                          
                                        </div>
                                        <h3 className="m-0 text-lg font-bold text-black leading-6">Direct card payment</h3>
                                        
                                    </div>
                                    <div className="m-0 mt-2">Lorem ipsum dolor sit ame ed consectetur nod.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[40px]">
                            <a href="#" className="inline-block align-middle text-sm leading-none text-white py-[23px] px-[46px] bg-[#e63a27] hover:bg-black hover:text-white  mb-10">Discover More</a>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/2 pr-4 pl-4">
                    <div className="inline-block relative before:absolute before:left-0 before:right-0 before:z-10 before:top-[20px] before:bottom-5 before:inset-x-0 before:bg-[#e63a27] before:-skew-x-3">
                        <div className="w-[10000px] h-[calc(100%_+_527px)] absolute top-[-120px] left-[325px] md:block hidden bg-[#f2ede9]"></div>
                        {/* <div className="w-48 h-48 bg-no-repeat absolute bg-[top_center] top-[-60px] right-[-113px]"></div> */}
                        <img className='relative max-w-full z-10' src={aboutImg1} alt=""/>
                        <div className="absolute md:block hidden items-center pl-8 pr-8 pt-6 pb-6 z-[12] bottom-[88px] bg-[#e63a27] right-[-38px]">
                            <h3 className="text-5xl font-bold text-white m-0 mr-4">25<i className='not-italic text-3xl relative top-[-15px]'>+</i></h3>
                            <p className="text-base text-white font-medium m-0 -mt-px leading-[1.25em] max-w-[85px]">Years
                                Experience</p>
                        </div>
                        <div className="uppercase text-white font-black tracking-normal absolute hidden md:block text-[130px] left-[calc(100%_-_54px)] top-[calc(50%_-_4px)] rotate-90 origin-[top_center]">Finance</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default About;