import React from 'react';
import blogImg1 from '../Images/blog-1-1.png'
import blogImg2 from '../Images/blog-1-2.png'
import blogImg3 from '../Images/blog-1-3.png'

const Blog = () => {
    return (
        <section class=" pt-28 pb-28">
            <div class="container mx-auto sm:px-4">
                <div class="mb-12 text-center">
                    <p class="uppercase text-sm font-bold m-0 leading-none relative top-[-1px] text-[#e63a27] tracking-widest	">What We’re Offering</p>
                    <h2 class="m-0 font-bold text-5xl relative -mt-1 text-black bottom-[-10px]">Our latest news updates <br/> &amp; articles</h2>
                </div>
                <div class="flex flex-wrap">
                    <div class="lg:w-1/3 md:w-full sm:w-full pr-4 pl-4">
                        <div class="relative bg-white">
                            <div class="relative overflow-hidden">
                                <div class="w-16 h-16 text-xs uppercase font-bold leading-none text-center justify-center items-center flex flex-col absolute bottom-0 right-0 z-10 text-white bg-[#e63a27] tracking-widest	"><span className='block tracking-normal text-xl mb-0'>05</span> Mar</div>
                                <img className='w-full scale-100	' src={blogImg1} alt="Which growth strategies you should adopt"/>
                                <a className='flex w-full h-full absolute top-0 left-0 justify-center items-center opacity-0 translate-x-[-20%]' href="#"></a>
                            </div>
                            <div class="bg-white relative text-center pt-8 pl-10 pr-10 pb-10 transition-all	border-1 border-gray-500">
                                <div class="flex justify-center items-center -mt-1 ">
                                    <a href="#" className='flex items-center text-sm font-normal  text-gray-700 hover:text-[#e63a27] '>
                                    <div className='mr-1 text-[#e63a27] hover:text-black'>   <i class="far fa-user-circle"></i>
                                    </div> by Admin</a>
                                    <a href="#" className='flex items-center text-sm font-normal transition-all text-gray-700 hover:text-[#e63a27] pl-6'>
                                        <div className='mr-1 text-[#e63a27] hover:text-black'>
                                        <i class="far fa-comments" ></i>
                                    </div>
                                     2 Comments</a>
                                </div>
                                <h3 class="m-0 text-2xl font-bold mt-1 mb-3 text-black tracking-widest "><a href="#">Which growth strategies you should adopt</a></h3>
                                <p class="m-0 mb-8">Duis aute irure dolor lipsum simply free text the local markets.</p>
                                <a href="#" class="flex w-full uppercase justify-center items-center text-xs font-bold text-black bg-gray-600 tracking-widest py-[13.75px]">Read More</a> 
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-1/3 md:w-full sm:w-full pr-4 pl-4">
                        <div class="relative bg-white">
                            <div class="relative overflow-hidden">
                                <div class="w-16 h-16 text-xs uppercase font-bold leading-none text-center justify-center items-center flex flex-col absolute bottom-0 right-0 z-10 text-white bg-[#e63a27] tracking-widest	"><span className='block tracking-normal text-xl mb-0'>05</span> Mar</div>
                                <img className='w-full scale-100	' src={blogImg2} alt="Which growth strategies you should adopt"/>
                                <a className='flex w-full h-full absolute top-0 left-0 justify-center items-center opacity-0 translate-x-[-20%]' href="#"></a>
                            </div>
                            <div class="bg-white relative text-center pt-8 pl-10 pr-10 pb-10 transition-all	border-1 border-gray-500">
                                <div class="flex justify-center items-center -mt-1 ">
                                    <a href="#" className='flex items-center text-sm font-normal  text-gray-700 hover:text-[#e63a27] '>
                                    <div className='mr-1 text-[#e63a27] hover:text-black'>   <i class="far fa-user-circle"></i>
                                    </div> by Admin</a>
                                    <a href="#" className='flex items-center text-sm font-normal transition-all text-gray-700 hover:text-[#e63a27] pl-6'>
                                        <div className='mr-1 text-[#e63a27] hover:text-black'>
                                        <i class="far fa-comments" ></i>
                                    </div>
                                     2 Comments</a>
                                </div>
                                <h3 class="m-0 text-2xl font-bold mt-1 mb-3 text-black tracking-widest "><a href="#">Which growth strategies you should adopt</a></h3>
                                <p class="m-0 mb-8">Duis aute irure dolor lipsum simply free text the local markets.</p>
                                <a href="#" class="flex w-full uppercase justify-center items-center text-xs font-bold text-black bg-gray-600 tracking-widest py-[13.75px]">Read More</a> 
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-1/3 md:w-full sm:w-full pr-4 pl-4">
                        <div class="relative bg-white">
                            <div class="relative overflow-hidden">
                                <div class="w-16 h-16 text-xs uppercase font-bold leading-none text-center justify-center items-center flex flex-col absolute bottom-0 right-0 z-10 text-white bg-[#e63a27] tracking-widest	"><span className='block tracking-normal text-xl mb-0'>05</span> Mar</div>
                                <img className='w-full scale-100	' src={blogImg3} alt="Which growth strategies you should adopt"/>
                                <a className='flex w-full h-full absolute top-0 left-0 justify-center items-center opacity-0 translate-x-[-20%]' href="#"></a>
                            </div>
                            <div class="bg-white relative text-center pt-8 pl-10 pr-10 pb-10 transition-all	border-1 border-gray-500">
                                <div class="flex justify-center items-center -mt-1 ">
                                    <a href="#" className='flex items-center text-sm font-normal  text-gray-700 hover:text-[#e63a27] '>
                                    <div className='mr-1 text-[#e63a27] hover:text-black'>   <i class="far fa-user-circle"></i>
                                    </div> by Admin</a>
                                    <a href="#" className='flex items-center text-sm font-normal transition-all text-gray-700 hover:text-[#e63a27] pl-6'>
                                        <div className='mr-1 text-[#e63a27] hover:text-black'>
                                        <i class="far fa-comments" ></i>
                                    </div>
                                     2 Comments</a>
                                </div>
                                <h3 class="m-0 text-2xl font-bold mt-1 mb-3 text-black tracking-widest "><a href="#">Which growth strategies you should adopt</a></h3>
                                <p class="m-0 mb-8">Duis aute irure dolor lipsum simply free text the local markets.</p>
                                <a href="#" class="flex w-full uppercase justify-center items-center text-xs font-bold text-black bg-gray-600 tracking-widest py-[13.75px]">Read More</a> 
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Blog;