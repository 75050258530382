import React from 'react';
import background from '../Images/video-bg-1-1.jpg'

const Video = () => {
    return (
        <section className="bg-fixed relative bg-cover bg-black bg-center-center before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:bg-[#1c1c27]	 before:opacity-60 pt-80 pb-20   " style={{ backgroundImage: `url(${background})`}}>
        <div className="bg-hero3 absolute top-0 left-0 right-0 bottom-0 bg-no-repeat md:block hidden z-0"></div>
        <div className="container mx-auto sm:px-4">
            <div className="flex flex-wrap">
                <div className="lg:w-1/2 pr-4 pl-4">
                    <div className="video-one__content">
                        <a href="#" className="video-popup w-24 h-24 flex justify-center items-center relative rounded-[50%] border-2 border-[rgba(255, 255, 255, 0.1)] ">
                            <div className='flex justify-center items-center bg-[#e63a27] text-white text-xs w-16 h-16 rounded-[50%] hover:bg-white hover:text-black'>
                            <i className="fa fa-play"></i>
                            </div>
                            <span className="ripple"></span>
                        </a>
                        <h3 className="m-0 text-black font-extrabold mt-10 text-5xl">Hundreds of customers <br/> trust our company</h3>
                    </div>
                </div>
                <div className="lg:w-1/2 pr-4 pl-4">
                    <ul className="list-unstyled m-0 grid grid-cols-2	gap-5	">
                        <li className='flex items-center text-black text-base font-medium pl-8 pr-8 pt-3 pb-4 bg-white bg-transparent		'>
                            {/* <!-- <i className="icon-tick"></i> --> */}
                            Expert &amp; Certified
                        </li>
                        <li className='flex items-center text-black text-base font-medium pl-8 pr-8 pt-3 pb-4 bg-gray-500 bg-transparent'>
                            {/* <!-- <i className="icon-tick"></i> --> */}
                            Quality Services
                        </li>
                        <li className='flex items-center text-black text-base font-medium pl-8 pr-8 pt-3 pb-4 bg-gray-500 bg-transparent'>
                         {/* <i className="icon-tick"></i> */}
                            Quick Loan Order
                        </li>
                        <li className='flex items-center text-black text-base font-medium pl-8 pr-8 pt-3 pb-4 bg-white bg-transparent		'>
                            {/* <!-- <i className="icon-tick"></i> --> */}
                            Status Monitor
                        </li>
                        <li className='flex items-center text-black text-base font-medium pl-8 pr-8 pt-3 pb-4 bg-white bg-transparent		'>
                            {/* <!-- <i className="icon-tick"></i> --> */}
                            Status Monitor
                        </li>
                        <li className='flex items-center text-black text-base font-medium pl-8 pr-8 pt-3 pb-4 bg-gray-500 bg-transparent'>
                            {/* <!-- <i className="icon-tick"></i> --> */}
                            Easy to Use
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Video;