import React from 'react'
import headerImg1 from '../Images/Group 8344.png'
import headerImg2 from '../Images/Group 8346.png'

function Header() {
  return (
    <div> <div className="relative top-[-2px]">
    erfetgdetygr
   </div>
   <nav className="relative no-underline z-50">
       <div className="container mx-auto sm:px-4 max-w-full  flex items-center">
           <div className="absolute left-0 h-32 bg-white flex justify-center items-center top-[-40px] w-[260px]">

          

               <a href="#">
                   <img src={headerImg1} className='pt-10  h-28 relative z-10' alt="Finlon"/>
               </a>
           </div>
           <div className="no-underline pl-[375px] mb-16">
           <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 " id="navbarTogglerDemo02">
    <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-white  rounded md:bg-transparent md:text-[#e63a27] md:p-0 md:dark:text-blue-500" aria-current="page">Home</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#e63a27] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#e63a27] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#e63a27] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Pages</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#e63a27] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Features</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#e63a27] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">News</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#e63a27] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact Us</a>
      </li>
    </ul>
  </div>
           </div>
           <div className="ml-auto flex items-center pr-16">
               {/* <a href="#" className="text-xl mr-8  md:hidden text-black hover:text-[#e63a27]  cursor-pointer">
                   <i className="fa fa-bars"></i>
               </a> */}
             
               <a href="#" className=" mb-16 text-xl mr-8 md:block  hidden bg-[#e63a27] text-black hover:text-white hover:bg-black py-[19px] px-[40px]">Request Loan</a>
              
           </div>
       </div>
   </nav>
  

   {/* <div className="stricky-header stricked-menu main-menu">
       <div className="sticky-header__content">
       <div className="container-fluid">
           <div className="main-menu__logo"> 

               <svg xmlns="http://www.w3.org/2000/svg" className="main-menu__logo__shape-1" viewBox="0 0 317 120">
                   <path d="M259.856,120H0V0H274l43,37.481Z"></path>
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" className="main-menu__logo__shape-2" viewBox="0 0 317 120">
                   <path d="M259.856,120H0V0H274l43,37.481Z"></path>
               </svg>


               <a href="#">
                   <img src={headerImg2}width="140" height="51" alt="Finlon"/>
               </a>
           </div> 
          
        <div className="main-menu__nav">
               <ul className="main-menu__list">
                   <li className="dropdown current">
                       <a href="#">Home</a>
                       
                   </li>
                   <li>
                       <a href="#">About</a>
                   </li>

                   <li className="dropdown"><a href="#">Services</a>
                      
                   </li>
                   <li className="dropdown"><a href="#">Pages</a>
                       
                        
                  
                   </li>
                   <li className="dropdown"><a href="#">Features</a>
                       
                   </li>
                   <li className="dropdown"><a href="#">News</a>
                   </li>
                   <li><a href="#">Contact</a></li>
               </ul>
           </div> 
           
           <div className="main-menu__right">
               <a href="#" className="main-menu__toggler mobile-nav__toggler">
                   <i className="fa-solid fa-bars"></i>
               </a>
              
               <a href="#" className="thm-btn main-menu__btn">Request Loan</a>
            
           </div>
       </div>
   </div>
   </div> */}
   </div>
  )
}
;
export default Header