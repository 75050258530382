import React from 'react';
import loanImg from '../Images/loan-calculator-1-1.png'

const Loan = () => {
    return (
        <section className="relative z-10 bg-hero before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:bg-no-repeat before:bg-center-top before:bg-hero	 pt-28">
        <div className="container mx-auto sm:px-4 relative">
            <div className="loan-calculator__top">
                <div className="flex flex-wrap ">
                    <div className="md:w-1/2 pr-4 pl-4">
                        <div className="block-title text-left">
                            <p className="uppercase text-sm font-bold m-0 leading-none relative text-[#e63a27] top-[-1px] tracking-widest">calculate your rate</p>
                            <h2 className="m-0 font-bold text-4xl relative -mt-1 text-black tracking-widest bottom-[-10px]">How much do you need loan for you?</h2>
                        </div>
                    </div>
                    <div className="md:w-1/2 pr-4 pl-4">
                        <p className="mb-12 mt-10">Nullam vel nibh facilisis lectus fermentum ultrices quis non
                            risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea
                            dictumst.</p>
                    </div>
                </div>
            </div>

            <div className="loan-calculator__box">  
                <div className="flex flex-wrap">
                    <div className="md:w-1/2 pr-4 pl-4">
                        <form className="p-10 mt-10 bg-white shadow-custom" action="contact.html" id="loan-calculator" data-interest-rate="15">
                            <div className="flex justify-between items-center text-sm mb-3 text-gray-700">
                                <span>$1000</span>
                                <span>$40000</span>
                            </div>
                            <div className="mb-12">
                               <div className='border-4 border-gray-300 rounded-2xl'>

                               </div>
                               
                            </div>
                            <div className="flex justify-between items-center text-sm mb-3 text-gray-700">
                                <span>1 Month</span>
                                <span>12 Months</span>
                            </div>
                            <div className="mb-12">
                               <div className='border-4 border-gray-300 rounded-2xl'>

                               </div>
                               
                            </div>
                            <div className="flex justify-between items-center text-xl mb-3 text-black">
                                <span>Pay Monthly
</span>
                                <span>$2300

</span>
                            </div>
                            <div className="mb-12">
                               <div className='border border-gray-300 rounded-2xl'>

                               </div>
                               
                            </div>    
                            <div className="flex justify-between items-center text-xl mb-3 text-black">
                                <span>Term of Use
</span>
                                <span>8 Months

</span>
                            </div>
                            <div className="mb-12">
                               <div className='border border-gray-300 rounded-2xl'>

                               </div>
                               
                            </div>  
                            <div className="flex justify-between items-center text-xl mb-3 text-black">
                                <span>Total Pay Back
</span>
                                <span>$ 18400

</span>
                            </div>
                        
                        
                           <div className='my-10'>
                            
                            <a href="#" className="mt-10 text-white bg-[#e63a27] py-[14px] px-[20px] hover:bg-black">Apply For Loan</a>
                            </div>
                            <div className="text-sm font-light mt-5 tracking-widest">*These calculators are provided only as general
                                self-help
                                Planning
                                Tools. Results depend on other factors.</div>
                        </form>
                    </div>
                    <div className="lg:w-1/2 pr-4 pl-4">
                        <div className="relative">
                            <img src={loanImg} alt=""/>
                            <div className="bg-no-repeat items-start justify-center absolute z-10 md:block hidden  w-[398px] h-[295px]  bottom-[80px] right-[-40px] bg-hero2" data-wow-duration="1500ms" >
                                <div className="h-32 mt-14 ml-20 pl-8 pt-5 w-60 bg-[#e63a27] border-4 border-black">
                                    <h3 className="m-0 text-white text-5xl font-semibold leading-none">98<span className='text-4xl relative top-[-20px] right-[3px]'>%</span></h3>
                                  
                                    <p className="text-xl text-white leading-none font-normal mt-1">Satisfied Customers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Loan;