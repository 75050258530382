import React from 'react';
import aboImg from "../images/about2.png"



const about = () => {
    return (
        <div id="about" className="pt-[90px] pb-[60px] px-0">
        <div className="container  mx-auto sm:px-4">
            <div className="flex flex-wrap ">
                <div className="md:w-full pr-4 pl-4">
                    <div className="p-20 border-[#ddd] border-dashed border-[1px]">
                        <div className="flex flex-wrap">
                            <div className="md:w-1/2 pr-4 pl-4">
                                <div className="pb-0 mb-0 text-center">
                                    <h2 className='text-5xl font-semibold text-white'>About Us</h2>
                                </div>
                                <div className="float-right w-full text-left">
                                    <p className='leading-8 pt-8 font-normal pb-10 text-base text-white'>It is a long established fact that a reader will be distracted bIt is a long established fact that a reader will be distracted by y It is a long established fact that a reader will be distracted by It is a long established fact that a reader will be distracted by </p>
                                    <a className="inline-block text-white max-w-[225px] h-[67px] leading-[67px] w-full text-lg text-center font-medium transition-[ease-in] duration-[all] delay-[0.5s] border-[#ffffff] border-solid border-[2px]" href="#">Read More</a>
                                </div>
                            </div>
                            <div className="md:w-1/2 pr-4 pl-4">
                                <div className="float-right">
                                    <figure>
                                        <img className="max-w-full" src={aboImg} alt="" />
                                        </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default about;