import React from 'react';
import background from '../Images/pra.png'

const Navbar = () => {
    return (
        <section id="slider1" className="p-0 slider-1 md:pt-1 pt-10">
        
            
        
          <div className="w-screen bg-cover bg-center bg-hero5" >
            <div className="h-[100vh] " >
            
            
        
            
            <div className="container mx-auto sm:px-4 ">
              <div className="flex flex-wrap">
                <div className="sm:w-full  md:w-full  lg:w-full  xl:w-2/3 pr-4 pl-4">
                  <div className="slide__content">
             
                    <h2 className="text-white mb-3 mt-48 text-center md:text-8xl text-4xl">We provide you a financial loans</h2>
                    <p className="md:text-base leading-6 md:font-bold text-sm  text-white pr-12 mb-8 text-center">WNullam vel nibh facilisis lectus fermentum ultrices quis non risus.</p>
                    <a href="#" class=" capitalize relative text-sm font-bold w-40 h-16   p-0 bg-orange-600 text-white text-center mr-30 px-10 py-4 rounded-xl md:ml-[40%] ml-14 border-2 border-[#ff5e14] hover:bg-black">Apply for Loan</a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div></div>
          

      </section>
    );
};

export default Navbar;