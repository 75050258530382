import React from "react";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Work from "./Components/Work";
import Testinomial from "./Components/Testinomial";
import Graf from "./Components/Graf";
import About from "./Components/About";
import Wallet from "./Components/Wallet";
import Topsection from "./Components/Topsection";
import Header from "./Components/Header";
import { Helmet } from "react-helmet";

const Finance = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Banking || Finance </title>
      </Helmet>

      <div className="bg-hero6 w-screen overflow-hidden  ">
        <Header />
        <Topsection />
        <Wallet />
        <About />
        <Graf />
        <Testinomial />
        <Work />
        <Contact />
        <Footer />
      </div>
    </>
  );
};
export default Finance;
