import React from 'react';

const Fact = () => {
    return (
        <section class="bg-black bg-center-center pt-28 pb-20">
        <div class="container mx-auto sm:px-4">
            <div class="flex flex-wrap md:pl-1 pl-16 ">
                <div class="lg:w-1/4  md:w-1/2 pr-4 pl-4">
                    <div class="text-center -mt-2">
                        <div class="text-6xl font-bold text-white tracking-widest  whitespace-none">
                            <span class="inline-flex">
                                <span class="count-text" data-stop="90" data-speed="1500"></span>
                            </span>%
                        </div>
                        <h3 class="text-lg m-0 text-white inline-block pt-2 pb-3 pl-8 pr-8 mt-6 border-2 border-[#e63a27]">Loans Approve</h3>
                    </div>
                </div>
                <div class="lg:w-1/4  md:w-1/2 pr-4 pl-4">
                    <div class="text-center -mt-2">
                        <div class="text-6xl font-bold text-white tracking-widest  whitespace-none">
                            <span class="inline-flex">
                                <span class="count-text" data-stop="90" data-speed="1500"></span>
                            </span>$k
                        </div>
                        <h3 class="text-lg m-0 text-white inline-block pt-2 pb-3 pl-8 pr-8 mt-6 border-2 border-[#e63a27]">Daily Payments
</h3>
                    </div>
                </div>
                <div class="lg:w-1/4  md:w-1/2 pr-4 pl-4">
                    <div class="text-center -mt-2">
                        <div class="text-6xl font-bold text-white tracking-widest  whitespace-none">
                            <span class="inline-flex">
                                <span class="count-text" data-stop="90" data-speed="1500"></span>
                            </span>k
                        </div>
                        <h3 class="text-lg m-0 text-white inline-block pt-2 pb-3 pl-8 pr-8 mt-6 border-2 border-[#e63a27]">Happy Customers
</h3>
                    </div>
                </div>
                <div class="lg:w-1/4  md:w-1/2 pr-4 pl-4">
                    <div class="text-center -mt-2">
                        <div class="text-6xl font-bold text-white tracking-widest  whitespace-none">
                            <span class="inline-flex">
                                <span class="count-text" data-stop="90" data-speed="1500"></span>
                            </span>290
                        </div>
                        <h3 class="text-lg m-0 text-white inline-block pt-2 pb-3 pl-8 pr-8 mt-6 border-2 border-[#e63a27]">Expert People</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Fact;