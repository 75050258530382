import React from 'react';

import aboutImg from "../images/Group 8297.png"
import aboutImga from "../images/menu_btn.png"

function Header()
{
    return (
        <div className="head-top">
            <div className="container  max-w-full mx-auto sm:px-4">
                <div className="flex flex-wrap ">
                    <div className="sm:w-1/4 pr-4 pl-4">
                        <div className="logo">
                            
                            <a className='text-white text-2xl font-bold ml-10' href="#">
                           
                                <img className= ' md:ml-10 ml-28' src={aboutImg} alt="" />
                                </a>
                        </div>
                    </div>
                   
                    <div className="sm:w-3/4 sm:pr-4 sm:pl-40 pt-10">
                        <ul className=" text-right md:block hidden">
                           
                            <li>
                                <button class="cursor-pointer bg-transparent  text-white" >
                                    <img src={aboutImga} alt="" />
   </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;