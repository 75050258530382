import React from 'react';

import projectImg from "../Images/img-6.png"
import projectImg1 from "../Images/img-2.png"
import projectImg3 from "../Images/search-icon.png"
import projectImg2 from "../Images/img-3.png"
import projectImg4 from "../Images/img-4.png"
import projectImg5 from "../Images/img-5.png"
import iconImg from "../Images/icon-3.png"
import iconImg1 from "../Images/icon-4.png"

import iconImg2 from "../Images/icon-2.png"
import iconImg3 from "../Images/icon-1.png"

function Project ()  {
    return (
        <div>
        <div className="w-full float-left   pb-24">
        {/* pb-24 */}
        <div className="container mx-auto sm:px-4">
          
          <div className="flex flex-wrap ">
            <div className="md:w-1/2 pr-4 md:pl-36 ">
              <div className="float-left pt-48">
              <div className='flex justify-center items-center'>

    
<div className='w-16 h-4 md:mr-60 mr-28 bg-red-500 rounded bottom-[-80px]  '>

</div>    </div>
                <h1 className="  text-4xl text-gray-800 pt-2 uppercase font-bold">Our projects</h1>
                <p className=" text-base text-black ml-0 ">It is a long established fact that a reader will be distracted by the readable  </p>
                <div className="w-40 float-left mt-8 relative "><a className='w-full  float-left text-base text-blue-400 text-center py-[10px] px-[0px] rounded-lg border-2  border-teal-300	 hover:text-gray-900 hover:border-2 hover:border-red-400	' href="#">Read More </a></div>
                <div className="w-full float-left relative left-[-80px] top-[-50px]">
                    <img src={projectImg} alt="" />

                    </div>
              </div>
            </div>
            <div className="md:w-1/2 pr-4 pl-4">
              <div className="w-full float-left">
                <div className="md:w-6/12 float-left">
                  <div className="relative mt-3">
                    <div className='w-full'>
                   <img src={projectImg1}  alt="Avatar" className="opacity-100 block w-80  "  />
                   </div>
                    <div className="opacity-0 absolute text-center top-[50%] left-[50%] translate-y-2/4	 translate-x-2/4	">
                      <div className="text-white text-base py-[16px] px-[32px]">
                       <img src={projectImg3} alt="" />

                        </div>
                      <h2 className="w-full float-left text-sm text-black text-center ">Established Fact</h2>
                    </div>
                  </div>
                  <div className="relative mt-3">
                  <div className=' '>
                   <img src={projectImg2}  alt="Avatar" className="opacity-100 block w-80  "  />
                   </div>
                    <div className="opacity-0 absolute text-center top-[50%] left-[50%]">
                      <div className="text-white text-base py-[16px] px-[32px]">
                      <img src={projectImg3} alt="" />

                        </div>
                      <h2 className="w-full float-left text-sm text-black text-center">Established Fact</h2>
                    </div>
                  </div>
                </div>
                <div className="float-left md:mt-16 mt-6 md:ml-3 md:w-[47%]">
                  <div className="relative md:mt-3">
                  <div className='w-full mr-2'>
                   <img src={projectImg4}  alt="Avatar" className="opacity-100 block w-80 h-auto  "  />
                   </div>
                    <div className="opacity-0 absolute text-center top-[50%] left-[50%]">
                      <div className="text-white text-base py-[16px] px-[32px]">
                      <img src={projectImg3} alt="" />

                        </div>
                      <h2 className="w-full float-left text-sm text-black text-center">Established Fact</h2>
                    </div>
                  </div>
                  <div className="relative mt-3">
                  <div className='w-full'>
                   <img src={projectImg5}  alt="Avatar" className="opacity-100 block w-80 h-auto  "  />
                   </div>
                    <div className="opacity-0 absolute text-center top-[50%] left-[50%]">
                      <div className="text-white text-base py-[16px] px-[32px]">
                      <img src={projectImg3} alt="" />

                        </div>
                      <h2 className="w-full float-left text-sm text-black text-center">Established Fact</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full float-left bg-red-500 h-auto pt-12 pb-10">
      <div className="container mx-auto sm:px-4">
        <div className="flex flex-wrap ">
          <div className="lg:w-1/4  pl-4 sm:w-1/2 pr-4 pl">
            <div className="w-full float-left text-center min-h-[70px]">
              <div className='flex justify-center items-center'>
               <img  src={iconImg} alt="" />
               </div>
                </div>
            <h3 className="w-full float-left text-center  font-bold text-white text-5xl">1000+</h3>
            <p className="w-full float-left text-xl text-center font-semibold text-white ml-0 mt-0">Years of Business</p>
          </div>
          <div className="lg:w-1/4  pl-4 sm:w-1/2 pr-4 pl">
            <div className="w-full float-left text-center  min-h-[70px]">
            <div className='flex justify-center items-center'>
               <img  src={iconImg1} alt="" />
               </div>

                </div>
            <h3 className="w-full float-left text-center  font-bold text-white text-5xl">20000+</h3>
            <p className="w-full float-left text-xl text-center font-semibold text-white ml-0 mt-0">Projects Delivered</p>
          </div>
          <div className="lg:w-1/4 pr-4 pl-4 sm:w-1/2  pl">
            <div className="w-full float-left text-center  min-h-[70px]">
            <div className='flex justify-center items-center'>
               <img  src={iconImg2} alt="" />
               </div>

                </div>
            <h3 className="w-full float-left text-center font-bold text-white text-5xl">10000+</h3>
            <p className="w-full float-left text-xl text-center font-semibold text-white ml-0 mt-0">Satisfied Customers</p>
          </div>
          <div className="lg:w-1/4 pr-4 pl-4 sm:w-1/2  pl">
            <div className="w-full float-left text-center  min-h-[70px]">
            <div className='flex justify-center items-center'>
               <img  src={iconImg3} alt="" />
               </div>

                </div>
            <h3 className="w-full float-left text-center  font-bold text-white text-5xl">1500+</h3>
            <p className="w-full float-left text-xl text-center font-semibold text-white ml-0 mt-0">Services</p>
          </div>
        </div>
      </div>
    </div>

      </div>
    );
};

export default Project