import React from 'react';
import aboutImg from "../Images/img-1.png"

function About() {
    return (
        <div className="w-full float-left pt-24   pb-24">
        <div className="container mx-auto sm:px-4">
          <div className="flex flex-wrap ">
            <div className="md:w-2/3 pr-4 pl-4">
            <div className='flex justify-center items-center'>

    
<div className='w-16 h-4 md:mr-60 mr-28 bg-red-500 rounded bottom-[-80px]  '>

</div>    </div>
              <h1 className="w-[100%] float-left text-4xl text-gray-800 pt-2 uppercase font-bold">WELCOME TO FINAnCIAL SERVICES</h1>
              <p className="float-left text-base text-black ml-0 w-[90%]">It is a long established fact that a reader will be distracted by the readable content of a page when looking <br /> at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as <br />  opposed to using 'Content here, content here', making it </p>
              <div className="w-40 float-left mt-8 relative"><a className='w-full float-left text-base text-blue-400 text-center py-[10px] rounded-lg border-2 border-[#88c6f8] hover:text-gray-900 hover:boreder-2 hover:border-[#ff5a58]' href="#">Read More </a></div>
            </div>
            <div className="">
              <div>
                <img className="w-full " src={aboutImg} alt="" />

                </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default About;