import React from 'react';
import hImg1 from '../Images/service-h-1.png'
import hImg2 from '../Images/service-h-2.png'
import hImg3 from '../Images/service-h-3.png'
import serviceImg1 from '../Images/services-1-1.png'
import serviceImg2 from '../Images/services-1-2.png'
import serviceImg3 from '../Images/services-1-3.png'

const Contant = () => {
    return (
        <section className="relative mt-20  mb-20 ">
            {/* <img src={hImg1} className="top-[105px] left-[125px]" alt=""/>
            <img src={hImg2} className="top-[1311px] right-[350px]" alt=""/>
            <img src={hImg3} className="bottom-[110px] right-[50px]" alt=""/> */}
            <div className="container mx-auto sm:px-4">
                <div className="block-title text-center">
                    <p className="uppercase text-sm font-bold m-0 leading-none relative text-[#e63a27] top-[-1px] tracking-widest">What We’re Offering</p>
                    <h2 className="m-0 font-bold text-4xl relative -mt-1 text-black tracking-widest bottom-[-10px]">We provide best services <br/> for your loans </h2>
                </div>
                <div className="flex flex-wrap pt-16 ">
                    <div className="lg:w-1/3  md:w-full  sm:w-full pr-4 pl-4 mb-10">
                        <div className="service-card">
                            <div className="relative overflow-hidden">
                                <img className='w-full scale-100 	' src={serviceImg1}alt="Auto Car Loan"/>
                               
                            </div>
                            <div className="pl-5 pr-5 relative -mt-5">
                                <div className="bg-white pl-10 pr-10 text-center relative transition-all border-b-2	border-current	after:w-full after:h-1 after:absolute after:left-0 after:bottom-[-3px] after:origin-left-center after:scale-100	 after:bg-black	">
                                    <div className="w-24 h-24 bg-white text-5xl flex justify-center items-center text-center ml-auto mr-auto -mb-12 relative z-10 shadow-custom text-[#e63a27] rounded-[50%] top-[-46px] transition-all">
                                        <i className="fa-solid fa-car"></i>
                                    </div>
                                    <h3 className="m-0 text-xl font-extrabold mt-6 mb-4 text-black">
                                        <a className='transition-all text-inherit hover:text-[#e63a27]' href="#">Auto Car Loan</a>
                                    </h3>
                                    <p className="service-card__text">Car Loan provide low interest many variations of passages of lorem ipsum have
                                        some.</p>
                                    <a href="#" className="w-10 h-10 flex justify-center items-center relative ml-auto mr-auto -mt-5 transition-all bg-gray-400 z-10 bottom-[-21px] text-[#e63a27] hover:text-white hover:bg-slate-500 ">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3  md:w-full  sm:w-full pr-4 pl-4 mb-10">
                        <div className="service-card">
                            <div className="relative overflow-hidden">
                                <img className='w-full scale-100	' src={serviceImg2}alt="Auto Car Loan"/>
                               
                            </div>
                            <div className="pl-5 pr-5 relative -mt-5">
                                <div className="bg-white pl-10 pr-10 text-center relative transition-all border-b-2	border-current	after:w-full after:h-1 after:absolute after:left-0 after:bottom-[-3px] after:origin-left-center after:scale-100	 after:bg-black	">
                                    <div className="w-24 h-24 bg-white text-5xl flex justify-center items-center text-center ml-auto mr-auto -mb-12 relative z-10 shadow-custom text-[#e63a27] rounded-[50%] top-[-46px] transition-all">
                                        <i className="fa-solid fa-ring"></i>
                                    </div>
                                    <h3 className="m-0 text-xl font-extrabold mt-6 mb-4 text-black">
                                        <a className='transition-all text-inherit hover:text-[#e63a27]' href="#">Wedding Loan</a>
                                    </h3>
                                    <p className="service-card__text">Car Loan provide low interest many variations of passages of lorem ipsum have
                                        some.</p>
                                    <a href="#" className="w-10 h-10 flex justify-center items-center relative ml-auto mr-auto -mt-5 transition-all bg-gray-400 z-10 bottom-[-21px] text-[#e63a27] hover:text-white hover:bg-slate-500 ">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3  md:w-full  sm:w-full pr-4 pl-4">
                        <div className="service-card">
                            <div className="relative overflow-hidden">
                                <img className='w-full scale-100	' src={serviceImg3}alt="Auto Car Loan"/>
                               
                            </div>
                            <div className="pl-5 pr-5 relative -mt-5">
                                <div className="bg-white pl-10 pr-10 text-center relative transition-all border-b-2	border-current	after:w-full after:h-1 after:absolute after:left-0 after:bottom-[-3px] after:origin-left-center after:scale-100	 after:bg-black	">
                                    <div className="w-24 h-24 bg-white text-5xl flex justify-center items-center text-center ml-auto mr-auto -mb-12 relative z-10 shadow-custom text-[#e63a27] rounded-[50%] top-[-46px] transition-all">
                                        <i className="fa-solid fa-house"></i>
                                    </div>
                                    <h3 className="m-0 text-xl font-extrabold mt-6 mb-4 text-black">
                                        <a className='transition-all text-inherit hover:text-[#e63a27]' href="#">Property Loan</a>
                                    </h3>
                                    <p className="service-card__text">Car Loan provide low interest many variations of passages of lorem ipsum have
                                        some.</p>
                                    <a href="#" className="w-10 h-10 flex justify-center items-center relative ml-auto mr-auto -mt-5 transition-all bg-gray-400 z-10 bottom-[-21px] text-[#e63a27] hover:text-white hover:bg-slate-500 ">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
          
                   
            </div>
          </div>
        </section>
    );
};

export default Contant;