import React from 'react';
import grafImg1 from "../images/graf1.jpg"
import grafImg2 from "../images/graf2.jpg"


function Graf()  {
    return (
        <div className="graf">
        <div className="container  mx-auto sm:px-4">
            <div className="flex flex-wrap ">
                <div className="md:w-full pr-4 pl-4">
                    <ul className="flex flex-wrap list-none pl-0 mb-0    border-[#989eae] border-solid border-[1px]" id="myTabMD" role="tablist">
                        <li>
                            <a className="inline-block py-2 px-20 no-underline bg-white font-bold text-black" id="home-tab-md" data-toggle="tab" href="#" role="tab" aria-controls="home-md" aria-selected="true">Bitcoin(BTC)</a>
                        </li>
                        <li>
                            <a className="inline-block py-2 px-20 no-underline text-white font-bold" id="profile-tab-md" data-toggle="tab" href="#" role="tab" aria-controls="profile-md" aria-selected="false">Bitcoin Cash(BCH)</a>
                        </li>
                        <li>
                            <a className="inline-block py-2 px-20 no-underline text-white font-bold" id="contact-tab-md" data-toggle="tab" href="#" role="tab" aria-controls="contact-md" aria-selected="false">Bitcoin Lite(LTC)</a>
                        </li>
                        <li>
                            <a className="inline-block py-2 px-20 no-underline text-white font-bold" id="contact-tab-md" data-toggle="tab" href="#" role="tab" aria-controls="contact-md" aria-selected="false">Bitcoin Ethereum(ETH)</a>
                        </li>
                        <li>
                            <a className="inline-block py-2 md:px-8 px-20 no-underline text-white font-bold" id="contact-tab-md" data-toggle="tab" href="#" role="tab" aria-controls="contact-md" aria-selected="false">Bitcoin Ripple(XRP)</a>
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0  break-words   border-1   mt-[15px] rounded-none border-[#989eae] border-solid border-[1px]" id="myTabContentMD">
                        <div className="  opacity-100 block" id="tab1" role="tabpanel" aria-labelledby="home-tab-md">
                        <div className="flex flex-wrap pt-4">
                                <div className="md:w-1/5 pr-4 pl-4 sm:w-1/3">
                                <div className=" pb-[18px] text-center">
                                        <h4 className='leading-[22px] text-[17px] font-medium text-white'><span className='text-[#face34]'>USD</span> 123456.09 <br/> Last Price </h4>
                                    </div>
                                </div>
                                <div className="md:w-1/5 pr-4 pl-4 sm:w-1/3">
                                <div className=" pb-[18px] text-center">
                                        <h4 className='leading-[22px] text-[17px] font-medium text-white'>USD 123456.09 <br/> Daily Change </h4>
                                    </div>
                                </div>
                                <div className="md:w-1/5 pr-4 pl-4 sm:w-1/3">
                                <div className=" pb-[18px] text-center">
                                        <h4 className='leading-[22px] text-[17px] font-medium text-white'>USD 123456.09 <br/> 24 Hour Low </h4>
                                    </div>
                                </div>
                                <div className="md:w-1/5 pr-4 pl-4 sm:w-1/3">
                                <div className=" pb-[18px] text-center">
                                        <h4 className='leading-[22px] text-[17px] font-medium text-white'>USD 123456.09 <br/> 24 Hour High </h4>
                                    </div>
                                </div>
                                <div className="md:w-1/5 pr-4 pl-4 sm:w-1/3">
                                <div className=" pb-[18px] text-center">
                                        <h4 className='leading-[22px] text-[17px] font-medium text-white'>USD 123456.09 <br/> Today Open </h4>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="pt-10 border-t-[#6c6d6d] border-t-[1px] border-solid">
                                <div className="flex flex-wrap ">
                                    <div className="md:w-1/2 pr-4 pl-4">
                                        <div className="mb-[30px] text-center">
                                            <figure className='m-0'>
                                                <h3 className=" text-xl text-white leading-[30px] font-bold text-left  pb-[15px]">Total Growth</h3>
                                               <img src={grafImg1} alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 pr-4 pl-4">
                                        <div className="mb-[30px] text-center">
                                            <h3 className=' text-xl leading-[30px] font-bold text-white text-left  pb-[15px]'>LiveTrades <span  className="float-right pr-[20%] text-[#face34]">USD 123456.09</span></h3>
                                            <figure className='m-0'><img className=" max-w-full" src={grafImg2} alt="" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Graf;