import React from 'react';

const Services = () => {
    return (
        <section className="relative z-10 mb-28">
        <div className="w-40 h-32 bg-no-repeat absolute left-[230px] top-[70px]"></div>
        <div className="container mx-auto sm:px-4">
            <div className="flex flex-wrap ">
                <div className="lg:w-1/3 pr-4 pl-4 md:w-full ">
                    <div className="bg-white p-12 relative shadow-custom before:w-40 before:h-16 before:left-0 before:absolute before:bg-[#f2ede9] before:bg-[ linear-gradient(90deg, #ffffff 0%, var(--thm-gray) 100%)]">
                        <div className="service-card-two__shape"></div>
                        <div className="flex mb-8">
                          
                        </div> <br/> <br/>
                        <div className="service-card-two__content">
                            <h3 className="m-0 text-2xl font-bold -mt-1 mb-5 leading-5	text-[#1c1c27]">
                                <a className='transition-all text-inherit hover:text-[#e63a27]' href="#">Safe and Secure
                                    Payments</a>
                            </h3>
                            <p className="m-0 mb-5">Duis aute irure dolor lipsum free is simply free text the
                                local markets in reprehenderit.</p>
                            <a href="#" className="uppercase text-xs font-semibold inline-block pb-0 text-[#1c1c27] tracking-widest	border-b-4 border-[#1c1c27] transition-all hover:text-[#e63a27] hover:border-[#e63a27]">More Detail</a>
                           
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/3 pr-4 pl-4 md:w-full ">
                    <div className="bg-white p-12 relative shadow-custom before:w-40 before:h-16 before:left-0 before:absolute before:bg-[#f2ede9] before:bg-[ linear-gradient(90deg, #ffffff 0%, var(--thm-gray) 100%)]">
                        <div className="service-card-two__shape"></div>
                        <div className="flex mb-8">
                          
                        </div> <br/> <br/>
                        <div className="service-card-two__content">
                            <h3 className="m-0 text-2xl font-bold -mt-1 mb-5 leading-5	text-[#1c1c27]">
                                <a className='transition-all text-inherit hover:text-[#e63a27]' href="#">Quick Payment Process</a>
                            </h3>
                            <p className="m-0 mb-5">Duis aute irure dolor lipsum free is simply free text the
                                local markets in reprehenderit.</p>
                            <a href="#" className="uppercase text-xs font-semibold inline-block pb-0 text-[#1c1c27] tracking-widest	border-b-4 border-[#1c1c27] transition-all hover:text-[#e63a27] hover:border-[#e63a27]">More Detail</a>
                           
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/3 pr-4 pl-4 md:w-full ">
                    <div className="bg-white p-12 relative shadow-custom before:w-40 before:h-16 before:left-0 before:absolute before:bg-[#f2ede9] before:bg-[ linear-gradient(90deg, #ffffff 0%, var(--thm-gray) 100%)]">
                        <div className="service-card-two__shape"></div>
                        <div className="flex mb-8">
                          
                        </div> <br/> <br/>
                        <div className="service-card-two__content">
                            <h3 className="m-0 text-2xl font-bold -mt-1 mb-5 leading-5	text-[#1c1c27]">
                                <a className='transition-all text-inherit hover:text-[#e63a27]' href="#">No Prepayment Charges</a>
                            </h3>
                            <p className="m-0 mb-5">Duis aute irure dolor lipsum free is simply free text the
                                local markets in reprehenderit.</p>
                            <a href="#" className="uppercase text-xs font-semibold inline-block pb-0 text-[#1c1c27] tracking-widest	border-b-4 border-[#1c1c27] transition-all hover:text-[#e63a27] hover:border-[#e63a27]">More Detail</a>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Services;