import React from 'react';
import testImg1 from '../Images/testi-1-1.png'
import testImg2 from '../Images/testi-1-2.png'
import testImg3 from '../Images/testi-1-3.png'

const Test = () => {
    return (
        <section className="bg-no-repeat bg-center-left	 pt-28 mb-40">
            <div className="container mx-auto sm:px-4 ">
                <div className="block-title text-center">
                    <p className="uppercase text-sm font-bold m-0 leading-none relative text-[#e63a27] top-[-1px] tracking-widest">our testimonials</p>
                    <h2 className="m-0 font-bold text-4xl relative -mt-1 text-black tracking-widest bottom-[-10px]">What they’re talking about <br/> our company</h2>
                </div>
                <div className="flex flex-wrap  ">
                    <div className="lg:w-1/3 pr-4 pl-4 md:w-full  pt-20 " >
                        <div className="bg-white relative pl-10 pr-10 pt-12 pb-10 shadow-custom hover:shadow-cus ">
                            <div className="flex items-center flex-wrap">
                                <div className="relative w-24 mr-0 mb-5 before:absolute before:w-full before:h-full before:top-[-10px] before:right-[-10px] before:border-2 before:border-dashed before:border-[#e63a27]">
                                    <img className='relative' src={testImg1} alt="Delia Riley"/>
                                </div>
                                <div className="testimonial-card__meta ml-10">
                                    <div className="flex items-center mb-3 text-xs text-[#e63a27]">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h3 className="m-0 text-xl font-bold">Delia Riley</h3>
                                    <p className="m-0 text-xs uppercase tracking-widest">Finance Manager</p>
                                </div>
                            </div>
                            <span className="w-full h-10 bg-no-repeat bg-center block mt-8 mb-5 bg-auto	"></span>
                            <div className="m-0">
                                <p>I loved the customer service you guys provided me. That was very nice and patient with questions I had. I
                                    would really like definitely come back here. Thank you for yours service.</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 pr-4 pl-4 md:w-full  pt-20 " >
                        <div className="bg-white relative pl-10 pr-10 pt-12 pb-10 shadow-custom hover:shadow-cus ">
                            <div className="flex items-center flex-wrap">
                                <div className="relative w-24 mr-0 mb-5 before:absolute before:w-full before:h-full before:top-[-10px] before:right-[-10px] before:border-2 before:border-dashed before:border-[#e63a27]">
                                    <img className='relative' src={testImg2} alt="Delia Riley"/>
                                </div>
                                <div className="testimonial-card__meta ml-10">
                                    <div className="flex items-center mb-3 text-xs text-[#e63a27]">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h3 className="m-0 text-xl font-bold">Essie Perez</h3>
                                    <p className="m-0 text-xs uppercase tracking-widest">Finance Manager</p>
                                </div>
                            </div>
                            <span className="w-full h-10 bg-no-repeat bg-center block mt-8 mb-5 bg-auto	"></span>
                            <div className="m-0">
                                <p>I loved the customer service you guys provided me. That was very nice and patient with questions I had. I
                                    would really like definitely come back here. Thank you for yours service.</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 pr-4 pl-4 md:w-full  pt-20 " >
                        <div className="bg-white relative pl-10 pr-10 pt-12 pb-10 shadow-custom hover:shadow-cus ">
                            <div className="flex items-center flex-wrap">
                                <div className="relative w-24 mr-0 mb-5 before:absolute before:w-full before:h-full before:top-[-10px] before:right-[-10px] before:border-2 before:border-dashed before:border-[#e63a27]">
                                    <img className='relative' src={testImg3} alt="Delia Riley"/>
                                </div>
                                <div className="testimonial-card__meta ml-10">
                                    <div className="flex items-center mb-3 text-xs text-[#e63a27]">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h3 className="m-0 text-xl font-bold">Delia Riley</h3>
                                    <p className="m-0 text-xs uppercase tracking-widest">Dustin Dunn</p>
                                </div>
                            </div>
                            <span className="w-full h-10 bg-no-repeat bg-center block mt-8 mb-5 bg-auto	"></span>
                            <div className="m-0">
                                <p>I loved the customer service you guys provided me. That was very nice and patient with questions I had. I
                                    would really like definitely come back here. Thank you for yours service.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Test;