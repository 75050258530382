import React from 'react';
import benImg1 from  '../Images/benefit-1-1.png'
import benImg2 from '../Images/2400225 1.png'
import benImg4 from '../Images/image 121.png'
import benImg3 from '../Images/image 122.png'
import background from '../Images/benefit-bg-1-1.jpg'

const Benifit = () => {
    return (
        <section class="relative bg-no-repeat overflow-hidden  bg-right-top	 pt-28">
        <div class="h-full absolute top-0 left-0 bg-no-repeat w-[574px] md:bg-[#e63a27] bg-center-bottom clip-path-poly	 wow fadeInLeft animated" data-wow-delay="000ms" data-wow-duration="1500ms" >
        {/* style={{ backgroundImage: `url(${background})`, visibility: "visible",  animationDuration : "1500ms", animationDelay: "0ms" , animationName : "fadeInLeft " }} */}
        </div>
        <div class="absolute top-0 w-24 h-full bg-gray-300 left-[604px] origin-top-left	skew-x-[-15deg]	"></div>
        <div class="container mx-auto sm:px-4">
            <div class="flex flex-wrap  ">
                <div class="lg:w-1/2 pr-4 pl-4">
                    <div class="relative " > 
                        <img className='relative max-w-full z-10' src={benImg1} alt=""/>
                        <div class="absolute bottom-0 left-0 p-16 z-10">
                            <h3 class="text-5xl font-semibold text-[#e63a27]">99.9%</h3>
                            <p class="m-0 text-lg text-white tracking-widest">Success Rates Guarantee</p>
                            
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/2 pr-4 pl-4">
                    <div class="benefit-one__content">
                        <div class="block-title text-left">
                            <p class="uppercase text-sm font-bold m-0 leading-none relative text-[#e63a27] top-[-1px] tracking-widest">our benefits</p> 
                            <h2 class="m-0 font-bold text-4xl relative -mt-1 tracking-widest text-black bottom-[-10px]">Why you should choose our company</h2>
                        </div>
                        <p class="m-0 mt-10 mb-12">Nullam vel nibh facilisis lectus fermentum ultrices quis non risus.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. Duis
                            porta, quam ut finibus ultrices, lorem lacus congue lorem et rutrum sapien magna tincidunt
                            magna.</p>
                         <div class="relative flex items-start">
                            <div class="relative mr-12">
                                <i className='ml-5 mt-5 md:block text-6xl relative text-[#e63a27] transition-colors	' >
                                    <img class="icon-bank" src={benImg4} alt=""/>
                                </i>
                            </div>
                            <div class="benefit-one__box__content">
                                <h3 class="m-0 text-xl font-bold mb-3 tracking-widest text-black">Lowest bank fees</h3>
                                <p class="m-0 mb-10">Lorem ipsum dolor consectetur notte massa sapien samet.
                                    Aucibus sed sem non, mattis commodo nisi.</p>
                            </div>
                        </div>
                        <div class="relative flex items-start">
                            <div class="relative mr-12">
                                <i className='ml-5 mt-5 md:block text-6xl relative text-[#e63a27] transition-colors	' >
                                    <img class="icon-bank" src={benImg3} alt=""/>
                                </i>
                            </div>
                            <div class="benefit-one__box__content">
                                <h3 class="m-0 text-xl font-bold mb-3 tracking-widest text-black">Up to 20.000$ limit
</h3>
                                <p class="m-0 mb-10">Lorem ipsum dolor consectetur notte massa sapien samet.
                                    Aucibus sed sem non, mattis commodo nisi.</p>
                            </div>
                        </div>
                        <div class="relative flex items-start">
                            <div class="relative mr-12">
                                <i className='ml-5 mt-5 md:block text-6xl relative text-[#e63a27] transition-colors	' >
                                    <img className="" src={benImg2} alt=""/>
                                </i>
                            </div>
                            <div class="benefit-one__box__content">
                                <h3 class="m-0 text-xl font-bold mb-3 tracking-widest text-black">Easy in 3 steps
</h3>
                                <p class="m-0">Lorem ipsum dolor consectetur notte massa sapien samet.
                                    Aucibus sed sem non, mattis commodo nisi.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Benifit;