import React from 'react';
import walletImg from "../images/wa1.svg.png"
import walletImgs from "../images/wa2.svg.png"
import walletImge from "../images/wa3.svg.png"
import walletImga from "../images/wa4.svg.png"

function Wallet()  {
    return (
        <div className="pt-[113px] pb-0 px-[30px]">
        <div className="container max-w-full mx-auto sm:px-4">
            <div className="flex flex-wrap ">
                <div className="lg:w-1/4  sm:w-1/2 pr-4 pl-4">
                    <div id="wa_hover" className="transition-[ease-in] duration-[all] delay-[0.5s] mb-[30px] pt-[25px] pb-[50px] px-[15px] bg-white text-center">
                        <i className='flex justify-center items-center'><img src={walletImg} alt="" /></i>
                        <h3 className='text-[#0c0b0b] text-[22px] uppercase transition-[ease-in] duration-[all] delay-[0.5s] font-bold leading-[22px] px-0 py-5'>ONLINE WALLET</h3>
                        <p className='text-[#191a1a] text-base leading-7 transition-[ease-in] duration-[all] delay-[0.5s]'>It is a long established fact that a reader will be distracted by </p>
                    </div>
                </div>
                <div className="lg:w-1/4  sm:w-1/2 pr-4 pl-4">
                    <div id="wa_hover" className="transition-[ease-in] duration-[all] delay-[0.5s] mb-[30px] pt-[25px] pb-[50px] px-[15px] bg-white text-center">
                        <i className='flex justify-center items-center'><img src={walletImgs} alt="" /></i>
                        <h3 className='text-[#0c0b0b] text-[22px] uppercase transition-[ease-in] duration-[all] delay-[0.5s] font-bold leading-[22px] px-0 py-5'>SEND COINS</h3>
                        <p className='text-[#191a1a] text-base leading-7 transition-[ease-in] duration-[all] delay-[0.5s]'>It is a long established fact that a reader will be distracted by </p>
                    </div>
                </div>
                <div className="lg:w-1/4  sm:w-1/2 pr-4 pl-4">
                    <div id="wa_hover" className="transition-[ease-in] duration-[all] delay-[0.5s] mb-[30px] pt-[25px] pb-[50px] px-[15px] bg-white text-center">
                        <i className='flex justify-center items-center'><img src={walletImge} alt="" /></i>
                        <h3 className='text-[#0c0b0b] text-[22px] uppercase transition-[ease-in] duration-[all] delay-[0.5s] font-bold leading-[22px] px-0 py-5'>MOBILE APP </h3>
                        <p className='text-[#191a1a] text-base leading-7 transition-[ease-in] duration-[all] delay-[0.5s]'>It is a long established fact that a reader will be distracted by </p>
                    </div>
                </div>
                <div className="lg:w-1/4  sm:w-1/2 pr-4 pl-4">
                    <div id="wa_hover" className="transition-[ease-in] duration-[all] delay-[0.5s] mb-[30px] pt-[25px] pb-[50px] px-[15px] bg-white text-center">
                        <i className='flex justify-center items-center'><img  src={walletImga} alt="" /></i>
                        <h3 className='text-[#0c0b0b] text-[22px] uppercase transition-[ease-in] duration-[all] delay-[0.5s] font-bold leading-[22px] px-0 py-5'>COIN MINING</h3>
                        <p className='text-[#191a1a] text-base leading-7 transition-[ease-in] duration-[all] delay-[0.5s]'>It is a long established fact that a reader will be distracted by </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Wallet;