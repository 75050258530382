import React from 'react';
import teamImg from "../Images/img-7.png"
import teamImg1 from "../Images/fb-icon.png"
import teamImg2 from "../Images/twitter-icon.png"
import teamImg3 from "../Images/linkedin-icon.png"
import teamImg4 from "../Images/img-8.png"
import teamImg5 from "../Images/img-9.png"
import teamImg6 from "../Images/img-10.png"

function Team  () {
    return (
        <div className="w-full float-left pt-24 pb-36">
      <div className="container mx-auto sm:px-4">
      <div className='flex justify-center items-center'>

    
<div className='w-16 h-4 bg-red-500 rounded bottom-[-80px]  '>

</div>    </div>
        <h1 className="w-full float-left text-4xl text-black pt-2 uppercase font-bold text-center pb-6">Our Team and experts</h1>
        <p className=" text-base text-black text-center">It is a long established fact that a reader will be distracted by the readable content of a </p>
        <div className="w-full float-left pt-24 pb-0">
          <div className="flex flex-wrap ">
            <div className="sm:w-1/4 pr-4 pl-4">
             <img className="w-full float-left text-center" src={teamImg} alt="" />
              <p className="w-full float-left text-lg text-center text-black ml-0 ">Readable</p>
              <p className="w-full float-left text-lg text-center text-red-500 m-0">Follow Us</p>
              <div className="w-full text-center">
                <ul className='m-0 p-0 inline-block'>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg1} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg2} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg3} alt="" /></a></li>
                </ul>
              </div>
            </div>
            <div className="sm:w-1/4 pr-4 pl-4">
             <img className="w-full float-left text-center" src={teamImg4} alt="" />
              <p className="w-full float-left text-lg text-center text-black ml-0 ">Readable</p>
              <p className="w-full float-left text-lg text-center text-red-500 m-0">Follow Us</p>
              <div className="w-full text-center">
                <ul className='m-0 p-0 inline-block'>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg1} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg2} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg3} alt="" /></a></li>
                </ul>
              </div>
            </div>
            <div className="sm:w-1/4 pr-4 pl-4">
             <img className="w-full float-left text-center" src={teamImg5} alt="" />
              <p className="w-full float-left text-lg text-center text-black ml-0 ">Readable</p>
              <p className="w-full float-left text-lg text-center text-red-500 m-0">Follow Us</p>
              <div className="w-full text-center">
                <ul className='m-0 p-0 inline-block'>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg1} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg2} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg3} alt="" /></a></li>
                </ul>
              </div>
            </div>
            <div className="sm:w-1/4 pr-4 pl-4">
             <img className="w-full float-left text-center" src={teamImg6} alt="" />
              <p className="w-full float-left text-lg text-center text-black ml-0 ">Readable</p>
              <p className="w-full float-left text-lg text-center text-red-500 m-0">Follow Us</p>
              <div className="w-full text-center">
                <ul className='m-0 p-0 inline-block'>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg1} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg2} alt="" /></a></li>
                  <li className='float-left pt-[20px] pr-[3px] pb-[0px] pl-[3px]'><a href="#"><img src={teamImg3} alt="" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

export default Team;