import React from 'react';

import hedImg from "../Images/Group 8295.png"
import headImg from "../Images/banner-img.png"

function Header () {
    return (
       
            
       <div className="w-full float-left">
      <div className="w-6/12 float-left">
        <nav className="relative flex flex-wrap items-center content-between py-3 px-4 pr-3 pl-3 text-lg text-gray-900 uppercase  bg-transparent">
          <div className="md:w-3/12 float-left md:ml-1 "><a className=''  href="#">
            <img className='  h-20' src={hedImg} alt="" />
                
            </a>
            </div>
            <div
              className="inline-flex items-center pl-0 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
           
             
                
           <>
           {/* <div className=''>
                   <svg class="w-6 h-6 " aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                   </div> */}
                  </>
               
            
            </div>
            <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 " id="navbarTogglerDemo02">
    <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-white  rounded md:bg-transparent md:text-yellow-500 md:p-0 md:dark:text-blue-500" aria-current="page">Home</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-yellow-500 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-yellow-500 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-yellow-500 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Team</a>
      </li>
      <li>
        <a href="#" class="block py-2 pl-3 pr-4 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-yellow-500 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact Us</a>
      </li>
    </ul>
  </div>
        </nav>
        <div className="md:w-full float-left md:pl-24 pl-20">
          <h1 className="w-full float-left md:text-6xl text-4xl text-gray-900 md:pt-20 uppercase font-bold">financial <br/>Service</h1>
          <p className="float-left text-base text-gray-900 ml-0 w-[90%]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever </p>
          <div className="w-full float-left pt-4 md:pt-20 ">
            <div className="w-40 float-left pb-10 "><a className='w-full float-left text-base text-black text-center uppercase font-bold py-[10px] border-2 border-[#ff5a58] rounded-lg hover:text-black hover:border-2 hover:border-[#ff5a58]' href="#">Read More </a></div>
            <div className="w-40 float-left md:ml-4"><a className='w-full float-left text-base text-gray-900 text-center uppercase font-bold py-[10px] border-2 border-[#4aafff] rounded-lg hover:text-black hover:border-2 hover:border-[#ff5a58] ' href="#">Contact Us</a></div>
          </div>
        </div>
      </div>
      <div className="md:w-6/12 float-left">
        <img src={headImg} alt="" className="w-full float-left" />
      </div>
    </div>
    );
};

export default Header;