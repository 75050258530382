import React from 'react';
import footerImg from '../Images/Group 3844.png';

function Footer() {
  return (
    <div>
      <div className="mt-24 pt-10 text-center bg-[#E24837] ">
        <div className="container mx-auto sm:px-4 ">
          <div className="flex flex-wrap ">
            <div className="md:w-1/3 pr-4 pl-4 mb-10">
              <div className="text-white">
                <h1 className='text-white font-semibold pb-4 md:text-6xl text-3xl'>About</h1>
                <p>
                  CERBOSYS is a software development company that provides
                  customized and creative services for online businesses. The
                  services we provide have been instrumental in helping shape
                  several start-up companies by meeting their changing needs.
                </p>
              </div>
            </div>
            <div className="md:w-1/3 pr-4 md:pl-4 pl-14 mb-10">
              <div className="text-white ">
                <h1 className='font-semibold pb-4 md:text-6xl text-3xl'>Address</h1>
                <p>Address: 15-16 Lakshya Vihar Indore</p>
                <p>Tel: 0731 499 3444</p>
                <p>website: www.cerbosys.com</p>
              </div>
            </div>
            <div className="md:w-1/3 pr-4 md:pl-4 pl-14">
              <div>
                <img src={footerImg} alt="" />
              </div>
              <div className="text-white flex gap-8 pl-10">
                <div className="icon">
                  <i className="fa-brands fa-facebook-f"></i>
                </div>
                <div className="icon">
                  <i className="fa-brands fa-twitter"></i>
                </div>
                <div className="icon">
                  <i className="fa-brands fa-linkedin-in"></i>
                </div>
                <div className="icon">
                  <i className="fa-brands fa-youtube"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-blue-500 mt-16 px-[0px] py-[25px]">
          <div className="container mx-auto sm:px-4">
            <div className="flex flex-wrap ">
              <div className="md:w-full pr-4 pl-4">
                <p className='text-white text-lg leading-5 text-center pt-0 font-normal pr-0'>© 2022 All Rights Reserved. Design by Cerbosys</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
}

export default Footer;
