import React from 'react';
import clientImg from "../Images/round-1.png"
import clientImg1 from "../Images/img-11.png"
import clientImg2 from "../Images/round-2.png" 

function Client  () {
    return (
        <div className="w-full  pt-24 pb-30 ">
          {/* pb-32 */}
      <div className="container mx-auto sm:px-4   ">
     
        
            <div className="">
               
        <div className='flex justify-center items-center'>

    
<div className='w-16 h-4 bg-red-500 rounded bottom-[-80px]  '>

</div>    </div>
              <h1 className="w-full float-left text-4xl text-black pt-2 uppercase font-bold text-center">what is syas our clients</h1>
              <div className="w-full text-center pt-24 pb-0">
                <ul className='m-0 p-0 inline-block'>
                  <li className='float-left pt-[50px] pr-[15px] pb-[0px] pl-[15px]'><img className="pt-[50px] md:block hidden"  src={clientImg} alt="" /></li>
                  <li className='float-left pt-[50px] pr-[15px] pb-[0px] pl-[15px]'><img className="image_11" src={clientImg1} alt="" /></li>
                  <li className='float-left pt-[50px] pr-[15px] pb-[0px] pl-[15px]'><img className="pt-[30px] md:block hidden" src={clientImg2} alt="" /></li>
                </ul>
                <p className="text-base text-black text-center pt-8 w-[70]">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</p>
              </div>
            </div>
           
         
       
      </div>
    </div>
    );
};

export default Client;