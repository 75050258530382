import React from 'react';

function Contact()  {
    return (
        <div className="md:pl-48 pb-10">
        <div className="container mx-auto sm:px-4 ">
            <div className="flex flex-wrap ">
                <div className="md:w-full pr-4 pl-4">
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="md:w-full pr-4 ">
                    <div className="pb-16 text-center">
                        <h2 className='text-5xl font-semibold text-white'>Requeste A call Back</h2>
                    </div>
                </div>
                <div className=" md:w-4/5 pr-4 pl-4 md:mx-1/6">
                    <form id="request" className="main_form">
                        <div className="flex flex-wrap ">
                            <div className="md:w-1/2 w-full pr-4 pl-4 ">
                                <input className="mb-6 w-full h-20 bg-transparent text-gray-700 text-base font-normal px-[15px] py-0 border-[#404b65] border-solid border-[1px]" placeholder="Full Name" type="type" name="Full Name"/>
                            </div>
                            <div className="md:w-1/2 w-full pr-4 pl-4">
                                <input className="mb-6 w-full h-20 bg-transparent text-gray-700 text-base font-normal px-[15px] py-0 border-[#404b65] border-solid border-[1px]" placeholder="Email" type="type" name="Email"/>
                            </div>
                            <div className="md:w-1/2 w-full pr-4 pl-4">
                                <input className="mb-6 w-full h-20 bg-transparent text-gray-700 text-base font-normal px-[15px] py-0 border-[#404b65] border-solid border-[1px]" placeholder="Phone number" type="type" name="Phone number"/>
                            </div>
                            <div className="md:w-1/2 w-full pr-4 pl-4">
                                <textarea className="mb-6 w-full h-20 bg-transparent text-gray-700 text-base font-normal pt-6 px-[15px] py-0 border-[#404b65] border-solid border-[1px]" placeholder="Message" type="type" Message="Name"></textarea>
                            </div>
                            <div className="md:w-1/2 w-full pr-4 pl-4 md:ml-64">
                                <button className="text-xl bg-transparent uppercase text-white w-full block mt-2 font-medium transition-[ease-in] duration-[all] delay-[0.5s] max-w-[202px] mx-auto my-0 px-0 py-3 rounded-[30px] border-[#fff] border-solid border-[2px]">Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Contact;